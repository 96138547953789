<template>
  <div class="product-wrapper">
    <div class="product-nav">
      <div class="text">
        {{ $t('product') }}
        <div class="line"></div>
      </div>
    </div>
    <!-- <div class="product-nav-link">
      <div>
        <router-link
          v-for="item in navLink"
          :key="item.prop"
          :to="item.link"
          tag="div"
          class="product-nav-link-router"
        >
          {{ $t(item.prop) }}
        </router-link>
      </div>
    </div> -->
    <div class="router">
      <router-view />
    </div>
  </div>
</template>
<script>
import { configLink } from '@/utils'
export default {
  name: 'Product',
   metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  data() {
    return {
      navLink: [...configLink[0].list]
    }
  },
}
</script>

<style lang="scss" scoped>
.product-wrapper {
  position: relative;
  .product-nav {
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('~@/assets/image/product/nav.jpg') no-repeat center center;
    .text {
      width: calc(1200px - (74px * 2));
      line-height: 60px;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 42px;
      .line {
        width: 69px;
        height: 1px;
        background: #fff;
        margin-top: 28px;
      }
    }
  }
  .product-nav-link {
    border-bottom: 1px solid rgba(225, 225, 225, 1);
    position: sticky;
    top: 70px;
    z-index: 10;
    background: #fff;
    &>div {
      width: 100%;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      .product-nav-link-router {
        margin: 0 24px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s;
        &.router-link-exact-active, &:hover {
          transition: color 0.3s;
          color: rgba(34, 34, 34, 1);
        }
      }
    }
  }
}
</style>
